<template>
  <b-modal
    id="device-form-modal"
    v-model="showModal"
    :title="isEdit? 'Edit Fleet' : 'Register Fleet'"
    centered
    hide-footer
    @shown="initModal"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <div
        class="mb-3"
        style="border: 1px solid #CED4DA"
      >
        <div
          class="fs-15 bg-light p-2"
          style="border-bottom: 1px solid #CED4DA"
        >
          Basic Information
        </div>
        <div class="p-2">
          <FleetFormInput
            v-model="form.vehicle_number"
            required
            :state="v$.form.vehicle_number.$dirty ? (hasErrors('vehicle_number') ? false : null) : null"
            :errors="getErrors('vehicle_number')"
          >
            <template #label>
              <b>Vehicle Number</b> (No. Polisi)
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.driver_name"
            label="Driver Name"
            :state="v$.form.driver_name.$dirty ? (hasErrors('driver_name') ? false : null) : null"
            :errors="getErrors('driver_name')"
          >
            <template #hint>
              <div class="d-flex justify-content-between">
                <div class="small mt-1 text-muted">
                  Maximum 40 characters
                </div>
                <FormCharacterCounter
                  class="float-end"
                  :field="form.driver_name"
                  :max-length="v$.form.driver_name.maxLength.$params.max"
                />
              </div>
            </template>
          </FleetFormInput>
          <FleetFormInput v-model="form.engine_number">
            <template #label>
              <b>Engine Number</b> (No. Seri Mesin)
            </template>
          </FleetFormInput>
        </div>
      </div>

      <div
        class="mb-3"
        style="border: 1px solid #CED4DA"
      >
        <div
          class="fs-15 bg-light p-2"
          style="border-bottom: 1px solid #CED4DA"
        >
          Device Activation
        </div>
        <div class="p-2">
          <FleetFormInput
            v-model="form.vin"
            required
            :state="v$.form.vin.$dirty ? (hasErrors('vin') ? false : null) : null"
            :errors="getErrors('vin')"
          >
            <template #label>
              <b>Vehicle Identification Number</b> (No. Seri Rangka)
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.device_id"
            label="Device ID"
            required
            :state="v$.form.device_id.$dirty ? (hasErrors('device_id') ? false : null) : null"
            :errors="getErrors('device_id')"
            hint="The device's identification number can be found on its enclosure"
          />
          <FleetFormInput
            v-model="form.device_eui"
            label="Device EUI"
            required
            :state="v$.form.device_eui.$dirty ? (hasErrors('device_eui') ? false : null) : null"
            :errors="getErrors('device_eui')"
            hint="The device's EUI number can be found on its enclosure"
          />
        </div>
      </div>

      <div
        class="mb-3"
        style="border: 1px solid #CED4DA"
      >
        <div
          class="d-flex justify-content-between bg-light p-2" 
          style="border-bottom: 1px solid #CED4DA"
        >
          <div class="col-8 fs-15 my-auto">
            Fleet Model
          </div>
          <div class="col-4">
            <FleetModelSelector
              v-model="selectedFleetModel"
              :options="fleetModelsOptions"
            />
          </div>
        </div>
        <div class="p-2">
          <FleetFormInput
            v-model="form.brand"
            label="Brand"
            type="select"
            :can-clear="true"
            has-icon
            :options="fleetBrandOptions"
          />
          <FleetFormInput
            v-model="form.series"
            label="Series"
          />
          <FleetFormInput
            v-model="form.fleet_type"
            label="Fleet Type"
            type="select"
            :can-clear="true"
            :options="fleetTypeOptions"
          />
          <FleetFormInput
            v-model="form.cargo_type"
            label="Cargo Type"
            type="select"
            :options="fleetCargoTypeOptions"
            :can-clear="true"
          />
          <FleetFormInput
            v-model="form.payload_capacity"
            type="number"
          >
            <template #label>
              <b>Payload Capacity</b> (Daya Angkut) <b>- kg</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.classification"
            label="Classification"
            type="select"
            :options="fleetClassificationOptions"
            :can-clear="true"
          />
          <FleetFormInput
            v-model="form.engine_displacement"
            type="number"
          >
            <template #label>
              <b>Engine Displacement</b> (Isi Silinder) <b>- cc</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.fuel_capacity"
            label="Fuel Tank Capacity - L"
            type="number"
          />
          <FleetFormInput
            v-model="form.fuel_type"
            label="Fuel Type"
            type="select"
            :options="fleetFuelTypeOptions"
            :can-clear="true"
          />
          <FleetFormInput
            v-model="form.can_bus_available"
            label="CAN Bus"
            required
            type="select"
            :options="fleetCanBusOptions"
            :state="v$.form.can_bus_available.$dirty ? (hasErrors('can_bus_available') ? false : null) : null"
            :errors="getErrors('can_bus_available')"
          />
          <!-- Fuel Ratio -->
          <FleetFormInput
            v-if="form.can_bus_available == 0"
            v-model="form.fuel_ratio"
            label="Fuel Ratio - km/L"
            type="number"
            :step="0.01"
          />
          
          <FleetFormInput
            v-model="form.emission_coefficient"
            label="Carbon Emission Coefficient - kgCO2/L"
            type="number"
            :step="0.01"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between gap-2">
        <b-button
          class="shadow-none"
          variant="soft-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="d-flex">
          <b-button
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { isEmptyObject } from '@/utils/helper';
import { fleetCanBusOptions, fleetTypeOptions, fleetBrandOptions, fleetCargoTypeOptions, fleetClassificationOptions, fleetFuelTypeOptions } from '@/utils/constant';

import FleetFormInput from '@/components/fleet/FleetFormInput.vue';
import FormCharacterCounter from '@/components/FormCharacterCounter.vue';
import FleetModelSelector from '@/components/fleet/FleetModelSelector.vue';

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    FormCharacterCounter,
    FleetFormInput,
    FleetModelSelector
  },
  props: {
    fleetDetail: {
      type: Object,
      required: true,
    },
    fleetModels: {
      type: Array,
      default() {
        return []
      }
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
      v$: useVuelidate()
    }
  },
  data() {
    return {
      form: {
        vehicle_number: '',
        driver_name: '',
        engine_number: '',
        vin: '',
        device_id: '',
        device_eui: '',
        brand: '',
        series: '',
        fleet_type: '',
        cargo_type: '',
        payload_capacity: '',
        classification: '',
        engine_displacement: '',
        fuel_capacity: '',
        fuel_type: '',
        can_bus_available: "0",
        fuel_ratio: '',
        emission_coefficient: 0,
      },
      selectedFleetModel: null,
      showModal: false,
      errors: {},
      fleetCanBusOptions,
      fleetTypeOptions, 
      fleetBrandOptions,
      fleetCargoTypeOptions,
      fleetClassificationOptions,
      fleetFuelTypeOptions
    };
  },
  computed: {
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
    isEdit() {
      return !isEmptyObject(this.fleetDetail);
    },
    fleetModelsOptions() {
      return this.fleetModels.map((model) => ({
        value: model.id,
        label: model.name
      }))
    }
  },
  
  validations: {
    form: {
      vehicle_number: {
        required: helpers.withMessage("Vehicle Number field is required.", required),
      },
      driver_name: {
        maxLength: helpers.withMessage("Maximum characters is 40.", maxLength(40)),
      },
      vin: {
        required: helpers.withMessage("Vehicle Identification Number field is required.", required),
        minLength: helpers.withMessage("Vehicle Identification Number must be 17 characters", minLength(17)),
        maxLength: helpers.withMessage("Vehicle Identification Number must be 17 characters", maxLength(17)),
      },
      device_id: {
        required: helpers.withMessage("Device ID field is required.", required),
      },
      device_eui: {
        required: helpers.withMessage("Device EUI field is required.", required),
      },
      can_bus_available: {
        required: helpers.withMessage("CAN Bus field is required.", required),
      },
    },
  },
  watch: {
    selectedFleetModel(value) {
      const keys = [
        'brand',
        'series',
        'fleet_type',
        'cargo_type',
        'payload_capacity',
        'classification',
        'engine_displacement',
        'fuel_capacity',
        'fuel_type',
        'can_bus_available',
        'fuel_ratio',
        'emission_coefficient',
      ]
      if(value) {
        const selectedModel = this.fleetModels.find((model) => model.id == value)
        keys.forEach((key) => this.form[key] = selectedModel[key])
      } else {
        keys.forEach((key) => this.form[key] = '')
      }
    },
    'form.fuel_type' : {
      immediate: false,
      handler(val) {
        if(val == 'diesel') {
          this.form.emission_coefficient = 2.70
        } else if(val == 'biodiesel') {
          this.form.emission_coefficient = 2.50
        } else if(val == 'motor gasoline') {
          this.form.emission_coefficient = 2.32
        }
      }
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.fleetDetail[key];
      }); if(this.form.can_bus_available == null) {
        this.form.can_bus_available = "0"
      } if(!this.isEdit) {
        this.form.emission_coefficient = 0
        this.form.can_bus_available = "0"
      }
      this.selectedFleetModel = null
    },
    async submitForm() {
      try {
        if (this.isEdit) {
          await this.$api.updateOrganizationDevice(this.fleetDetail.organization_id, this.fleetDetail.id, this.form);
          this.$emit('update-data', 'Edited');
          // toast 
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Updated Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        } else {
          await this.$api.createOrganizationDevice(this.selectedOrganization, this.form);
          this.$emit('update-data', 'Saved');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Saved Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        }
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.errors = error.response.data.errors;
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    initModal() {
      this.v$.$reset()
      this.resetForm();
    },
    hasErrors(key) {
      if (this.v$.form[key].$errors?.length) {
        return true;
      }
      if (this.errors[key]) {
        return true;
      }
      return false;
    },
    getErrors(key) {
      // Vuelidate Errors (frontend validation, before submit)
      if (this.v$.form[key].$errors?.length) {
        return this.v$.form[key].$errors?.map(
          (item) => item.$message
        );
      }
      // Errors from backend (backend validation, after submit)
      if (this.hasErrors(key)) {
        return this.errors[key];
      }
      return [];
    },
    resetErrors() {
      this.errors = {}
    }
  },
};
</script>